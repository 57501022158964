import { GetterTree } from 'vuex';
import { State } from '@/store/modules/vote';
import { RootState } from '@/store';
import { Vote } from '@/interfaces/vote';

export type Getters<S = State> = {
  voteList(state: S): Vote[];
};

export const getters: GetterTree<State, RootState> & Getters = {
  voteList: (state) => {
    return state.voteList;
  },
};
