import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from '@/store/modules/settings/mutations';
import { State } from '@/store/modules/settings';
import { RootState } from '@/store';

export enum ActionTypes {
  setIsLoading = 'SET_IS_LOADING',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.setIsLoading](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.setIsLoading]({ commit }, payload) {
    commit(MutationTypes.IS_LOADING, payload);
  },
};
