import { MutationTree } from 'vuex';
import { State } from '@/store/modules/vote';
import { Vote } from '@/interfaces/vote';

export enum MutationTypes {
  VOTE_LIST = 'SET_VOTE_LIST',
}

export type Mutations<S = State> = {
  [MutationTypes.VOTE_LIST](state: S, payload: Vote[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.VOTE_LIST](state, payload) {
    state.voteList = payload;
  },
};
