
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  menuController,
} from '@ionic/vue';
import {
  diamondOutline,
  diamondSharp,
  homeOutline,
  homeSharp,
  listOutline,
  listSharp,
  logoTwitter,
  logOut,
  mailOutline,
  mailSharp,
  searchOutline,
  searchSharp,
  starOutline,
  starSharp,
} from 'ionicons/icons';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteName, RoutePath } from '@/router/route-const';
import useStore from '@/store';
import { ActionTypes } from '@/store/modules/auth/actions';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'BaseMenu',
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonFooter,
    IonButton,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLogged = computed(() => store.getters.isLogged);
    const isActive = (path: string) => path === route.fullPath;

    const appPages = ref([
      {
        title: t('views.home'),
        url: RoutePath.HOME,
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
      {
        title: t('views.add-token'),
        url: RoutePath.ADD_TOKEN,
        iosIcon: starOutline,
        mdIcon: starSharp,
      },
      {
        title: t('views.promote'),
        url: RoutePath.PROMOTE,
        iosIcon: diamondOutline,
        mdIcon: diamondSharp,
      },
      {
        title: t('views.how-to-dyor'),
        url: RoutePath.HOW_TO_DYOR,
        iosIcon: searchOutline,
        mdIcon: searchSharp,
      },
      {
        title: t('views.contact-us'),
        url: RoutePath.CONTACT_US,
        iosIcon: mailOutline,
        mdIcon: mailSharp,
      },
    ]);

    watch(
      () => isLogged.value,
      (isLoggedIn) => {
        if (isLoggedIn) {
          appPages.value.push({
            title: t('views.manage-token'),
            url: RoutePath.MANAGE_TOKEN,
            iosIcon: listOutline,
            mdIcon: listSharp,
          });
        } else {
          appPages.value = appPages.value.filter(
            (page) => page.title !== t('views.manage-token')
          );
        }
      },
      { immediate: true }
    );

    const logout = async () => {
      await store.dispatch(ActionTypes.logout);
      setTimeout(() => menuController.toggle(), 250);
    };

    const nbClick = ref(0);
    const maskLogin = async () => {
      nbClick.value++;
      if (nbClick.value > 10) {
        await router.push({ name: RouteName.LOGIN });
        setTimeout(() => menuController.toggle(), 250);
        nbClick.value = 0;
      }
    };

    return {
      isLogged,
      appPages,
      logOut,
      logoTwitter,

      isActive,
      maskLogin,
      logout,
    };
  },
});
