import { GetterTree } from 'vuex';
import { State } from '@/store/modules/settings';
import { RootState } from '@/store';

export type Getters<S = State> = {
  isLoading(state: S): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
};
