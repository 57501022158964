export enum RouteName {
  REGISTER = 'register',
  LOGIN = 'login',
  HOME = 'home',
  NOT_FOUND = 'notFound',
  ADD_TOKEN = 'addToken',
  DETAIL_TOKEN = 'detailToken',
  EDIT_TOKEN = 'editToken',
  MANAGE_TOKEN = 'manageToken',
  PROMOTE = 'promote',
  HOW_TO_DYOR = 'howToDyor',
  CONTACT_US = 'contactUs',
  DISCLAIMER = 'disclaimer',
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

export enum RoutePath {
  ROOT = '',
  REGISTER = '/register',
  LOGIN = '/login',
  HOME = '/home',
  ADD_TOKEN = '/add-token',
  DETAIL_TOKEN = '/detail-token/:id',
  EDIT_TOKEN = '/edit-token',
  MANAGE_TOKEN = '/manage-token',
  PROMOTE = '/promote',
  HOW_TO_DYOR = '/how-to-dyor',
  CONTACT_US = '/contact-us',
  DISCLAIMER = '/disclaimer',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
}
