
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import BaseMenu from '@/components/base/BaseMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    BaseMenu,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
});
