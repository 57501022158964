import { MutationTree } from 'vuex';
import { State } from '@/store/modules/settings';

export enum MutationTypes {
  IS_LOADING = 'SET_IS_LOADING',
}

export type Mutations<S = State> = {
  [MutationTypes.IS_LOADING](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.IS_LOADING](state, payload) {
    state.isLoading = payload;
  },
};
