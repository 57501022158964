import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from '@/store/modules/auth/mutations';
import { State } from '@/store/modules/auth';
import { RootState } from '@/store';
import { User } from '@/interfaces/user';
import { login, register } from '@/services/api/auth';

export enum ActionTypes {
  login = 'LOGIN',
  register = 'REGISTER',
  logout = 'LOGOUT',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.login]({ commit }: AugmentedActionContext, payload: User): void;
  [ActionTypes.register](
    { commit }: AugmentedActionContext,
    payload: User
  ): void;
  [ActionTypes.logout]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.login]({ commit }, payload) {
    return login(payload).then((res) => {
      commit(MutationTypes.CURRENT_USER, res.data.user);
      commit(MutationTypes.TOKEN, res.data.token);
    });
  },
  [ActionTypes.register]({ commit }, payload) {
    return register(payload).then((res) => {
      commit(MutationTypes.CURRENT_USER, res.data.user);
      commit(MutationTypes.TOKEN, res.data.token);
    });
  },
  [ActionTypes.logout]({ commit }) {
    commit(MutationTypes.CURRENT_USER, undefined);
    commit(MutationTypes.TOKEN, undefined);
  },
};
