import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from '@/store/modules/token/mutations';
import { State } from '@/store/modules/token';
import { RootState } from '@/store';
import {
  createNewToken,
  deleteToken,
  getTokenById,
  getTokenByLabel,
  getTokenList,
  updateToken,
} from '@/services/api/token';
import { Token } from '@/interfaces/token';

export enum ActionTypes {
  setCurrentTokenById = 'SET_CURRENT_TOKEN_BY_ID',
  setCurrentTokenByLabel = 'SET_CURRENT_TOKEN_BY_LABEL',
  setTokenList = 'SET_TOKEN_LIST',
  createNewToken = 'CREATE_NEW_TOKEN',
  updateToken = 'UPDATE_TOKEN',
  deleteToken = 'DELETE_TOKEN',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.setCurrentTokenById](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<any>;
  [ActionTypes.setCurrentTokenByLabel](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<any>;
  [ActionTypes.setTokenList]({ commit }: AugmentedActionContext): void;
  [ActionTypes.createNewToken](
    { dispatch }: AugmentedActionContext,
    payload: Token
  ): void;
  [ActionTypes.updateToken](
    { dispatch, commit }: AugmentedActionContext,
    payload: Token
  ): void;
  [ActionTypes.deleteToken](
    { dispatch, commit }: AugmentedActionContext,
    payload: Token
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.setCurrentTokenById]({ commit }, payload) {
    return new Promise((resolve) => {
      getTokenById(payload).then((res) => {
        commit(MutationTypes.CURRENT_TOKEN, res.data);
        resolve(true);
      });
    });
  },
  [ActionTypes.setCurrentTokenByLabel]({ commit }, payload) {
    return new Promise((resolve) => {
      getTokenByLabel(payload).then((res) => {
        commit(MutationTypes.CURRENT_TOKEN, res.data);
        resolve(true);
      });
    });
  },
  [ActionTypes.setTokenList]({ commit }) {
    return getTokenList().then((res) => {
      commit(MutationTypes.TOKEN_LIST, res.data);
    });
  },
  [ActionTypes.createNewToken]({ dispatch }, payload) {
    return createNewToken(payload).then(() => {
      dispatch(ActionTypes.setTokenList);
    });
  },
  [ActionTypes.updateToken]({ dispatch, commit }, payload) {
    return updateToken(payload).then((res) => {
      commit(MutationTypes.CURRENT_TOKEN, res.data);
      dispatch(ActionTypes.setTokenList);
    });
  },
  [ActionTypes.deleteToken]({ dispatch, commit }, payload) {
    return deleteToken(payload).then(() => {
      commit(MutationTypes.CURRENT_TOKEN, undefined);
      dispatch(ActionTypes.setTokenList);
    });
  },
};
