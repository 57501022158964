export enum ApiRoute {
  AUTH_LOGIN = 'auth/login',
  AUTH_REGISTER = 'auth/register',
  TOKEN_CREATE = 'token/create',
  TOKEN_UPDATE = 'token/update',
  TOKEN_DELETE = 'token/delete',
  TOKEN_LIST = 'token/list',
  TOKEN_BY_ID = 'token/{id}',
  TOKEN_BY_LABEL = 'token/{label}',
  TOKEN_LIST_PROMOTED = 'token/list/promoted',
  TOKEN_LIST_ALL_TIME_POPULAR = 'token/list/all-time-popular',
  TOKEN_LIST_NEWEST = 'token/list/newest',
  TOKEN_LIST_TODAY_POPULAR = 'token/list/today-popular',
  VOTE_CREATE = 'vote/create',
}
