import { getters, Getters } from '@/store/modules/settings/getters';
import { mutations, Mutations } from '@/store/modules/settings/mutations';
import { actions, Actions } from '@/store/modules/settings/actions';
import {
  Store as VuexStore,
  Module,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import { RootState } from '@/store';

interface State {
  isLoading: boolean;
}

const state: State = {
  isLoading: false,
};

export { State };

export const settings: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters,
};

export type SettingsStore<S = State> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};
