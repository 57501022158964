import { GetterTree } from 'vuex';
import { State } from '@/store/modules/token';
import { RootState } from '@/store';
import { Token, TokenList } from '@/interfaces/token';

export type Getters<S = State> = {
  tokenList(state: S): TokenList | undefined;
  currentToken(state: S): Token | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  tokenList: (state) => {
    return state.tokenList;
  },
  currentToken: (state) => {
    return state.currentToken;
  },
};
