<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <BaseMenu />
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import BaseMenu from '@/components/base/BaseMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    BaseMenu,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
});
</script>
