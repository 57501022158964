import { createRouter, createWebHistory } from '@ionic/vue-router';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { RouteName, RoutePath } from '@/router/route-const';
import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutePath.ROOT,
    redirect: RoutePath.HOME,
  },
  {
    name: RouteName.NOT_FOUND,
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    name: RouteName.REGISTER,
    path: RoutePath.REGISTER,
    component: () => import('@/views/Register.vue'),
  },
  {
    name: RouteName.LOGIN,
    path: RoutePath.LOGIN,
    component: () => import('@/views/Login.vue'),
  },
  {
    name: RouteName.MANAGE_TOKEN,
    path: RoutePath.MANAGE_TOKEN,
    component: () => import('@/views/ManageToken.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: RouteName.HOME,
    path: RoutePath.HOME,
    component: () => import('@/views/Home.vue'),
  },
  {
    name: RouteName.ADD_TOKEN,
    path: RoutePath.ADD_TOKEN,
    component: () => import('@/views/AddToken.vue'),
  },
  {
    name: RouteName.DETAIL_TOKEN,
    path: RoutePath.DETAIL_TOKEN,
    component: () => import('@/views/DetailToken.vue'),
  },
  {
    name: RouteName.EDIT_TOKEN,
    path: RoutePath.EDIT_TOKEN,
    component: () => import('@/views/AddToken.vue'),
  },
  {
    name: RouteName.PROMOTE,
    path: RoutePath.PROMOTE,
    component: () => import('@/views/Promote.vue'),
  },
  {
    name: RouteName.HOW_TO_DYOR,
    path: RoutePath.HOW_TO_DYOR,
    component: () => import('@/views/HowToDyor.vue'),
  },
  {
    name: RouteName.CONTACT_US,
    path: RoutePath.CONTACT_US,
    component: () => import('@/views/ContactUs.vue'),
  },
  {
    name: RouteName.DISCLAIMER,
    path: RoutePath.DISCLAIMER,
    component: () => import('@/views/Disclaimer.vue'),
  },
  {
    name: RouteName.PRIVACY_POLICY,
    path: RoutePath.PRIVACY_POLICY,
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    name: RouteName.TERMS_AND_CONDITIONS,
    path: RoutePath.TERMS_AND_CONDITIONS,
    component: () => import('@/views/TermsAndConditions.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function checkAuthStatus(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (!store.getters.isLogged && to.matched.some((r) => r.meta.requiresAuth)) {
    return next(RoutePath.HOME);
  }
  next();
}

router.beforeEach(checkAuthStatus);

export default router;
