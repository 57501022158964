import { AxiosPromise } from 'axios';
import { ApiRoute } from '@/services/api/index';
import { request } from '@/utils/request';
import { Vote } from '@/interfaces/vote';

export const createVote = (data: Vote): AxiosPromise => {
  return request({
    method: 'post',
    url: ApiRoute.VOTE_CREATE,
    data,
  });
};
