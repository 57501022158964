import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from '@/store/modules/vote/mutations';
import { ActionTypes as TokenAction } from '@/store/modules/token/actions';
import { State } from '@/store/modules/vote';
import { RootState } from '@/store';
import { Vote } from '@/interfaces/vote';
import { createVote } from '@/services/api/vote';

export enum ActionTypes {
  addVote = 'ADD_VOTE',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ActionTypes.addVote](
    { commit, dispatch, state }: AugmentedActionContext,
    payload: Vote
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.addVote]({ commit, dispatch, state }, payload) {
    const voteList = state.voteList;
    voteList.push(payload);
    commit(MutationTypes.VOTE_LIST, voteList);
    return createVote(payload)
      .then(() => {
        dispatch(TokenAction.setTokenList);
      })
      .catch(() => {
        let voteList = state.voteList;
        voteList = voteList.filter((vote) => vote.tokenId !== payload.tokenId);
        commit(MutationTypes.VOTE_LIST, voteList);
      });
  },
};
