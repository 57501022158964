<template>
  <ion-menu content-id="main-content" type="push">
    <ion-content>
      <ion-list mode="ios">
        <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
          <ion-item
            router-direction="root"
            :router-link="p.url"
            lines="none"
            detail="false"
            class="hydrated"
            button
            :class="{ selected: isActive(p.url) }"
          >
            <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon" />
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            lines="none"
            detail="false"
            class="hydrated"
            button
            target="_blank"
            href="https://twitter.com/TokenVoteCo"
          >
            <ion-icon slot="start" :icon="logoTwitter" />
            <ion-label v-text="$t('views.twitter')" />
          </ion-item>
          <ion-item
            lines="none"
            detail="false"
            class="hydrated"
            button
            target="_blank"
            href="https://t.me/TokenVoteCo"
          >
            <ion-icon
              slot="start"
              :icon="require('@/assets/images/ic_telegram.svg')"
            />
            <ion-label v-text="$t('views.telegram')" />
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-button v-if="isLogged" color="primary" fill="clear" @click="logout">
        <ion-icon :icon="logOut" slot="start"></ion-icon>
        {{ $t('global.logout') }}
      </ion-button>
      <ion-button v-else fill="clear" expand="full" @click="maskLogin" />
    </ion-footer>
  </ion-menu>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  menuController,
} from '@ionic/vue';
import {
  diamondOutline,
  diamondSharp,
  homeOutline,
  homeSharp,
  listOutline,
  listSharp,
  logoTwitter,
  logOut,
  mailOutline,
  mailSharp,
  searchOutline,
  searchSharp,
  starOutline,
  starSharp,
} from 'ionicons/icons';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteName, RoutePath } from '@/router/route-const';
import useStore from '@/store';
import { ActionTypes } from '@/store/modules/auth/actions';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'BaseMenu',
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonFooter,
    IonButton,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLogged = computed(() => store.getters.isLogged);
    const isActive = (path: string) => path === route.fullPath;

    const appPages = ref([
      {
        title: t('views.home'),
        url: RoutePath.HOME,
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
      {
        title: t('views.add-token'),
        url: RoutePath.ADD_TOKEN,
        iosIcon: starOutline,
        mdIcon: starSharp,
      },
      {
        title: t('views.promote'),
        url: RoutePath.PROMOTE,
        iosIcon: diamondOutline,
        mdIcon: diamondSharp,
      },
      {
        title: t('views.how-to-dyor'),
        url: RoutePath.HOW_TO_DYOR,
        iosIcon: searchOutline,
        mdIcon: searchSharp,
      },
      {
        title: t('views.contact-us'),
        url: RoutePath.CONTACT_US,
        iosIcon: mailOutline,
        mdIcon: mailSharp,
      },
    ]);

    watch(
      () => isLogged.value,
      (isLoggedIn) => {
        if (isLoggedIn) {
          appPages.value.push({
            title: t('views.manage-token'),
            url: RoutePath.MANAGE_TOKEN,
            iosIcon: listOutline,
            mdIcon: listSharp,
          });
        } else {
          appPages.value = appPages.value.filter(
            (page) => page.title !== t('views.manage-token')
          );
        }
      },
      { immediate: true }
    );

    const logout = async () => {
      await store.dispatch(ActionTypes.logout);
      setTimeout(() => menuController.toggle(), 250);
    };

    const nbClick = ref(0);
    const maskLogin = async () => {
      nbClick.value++;
      if (nbClick.value > 10) {
        await router.push({ name: RouteName.LOGIN });
        setTimeout(() => menuController.toggle(), 250);
        nbClick.value = 0;
      }
    };

    return {
      isLogged,
      appPages,
      logOut,
      logoTwitter,

      isActive,
      maskLogin,
      logout,
    };
  },
});
</script>

<style lang="scss" scoped>
ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
