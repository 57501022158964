import { InjectionKey } from 'vue';
import {
  createStore,
  Store as VuexStore,
  useStore as baseUseStore,
} from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { auth, State as AuthState, AuthStore } from '@/store/modules/auth';

import {
  settings,
  State as SettingsState,
  SettingsStore,
} from '@/store/modules/settings';

import { token, State as TokenState, TokenStore } from '@/store/modules/token';

import { vote, State as VoteState, VoteStore } from '@/store/modules/vote';

export interface RootState {
  auth: AuthState;
  settings: SettingsState;
  token: TokenState;
  vote: VoteState;
}

export type RootStore = AuthStore<Pick<RootState, 'auth'>> &
  SettingsStore<Pick<RootState, 'settings'>> &
  TokenStore<Pick<RootState, 'token'>> &
  VoteStore<Pick<RootState, 'vote'>>;

export const key: InjectionKey<VuexStore<RootState>> = Symbol();
export const store = createStore<RootState>({
  plugins: [createPersistedState({ key: 'tokenvote-app-web-mobile' })],
  modules: {
    auth,
    settings,
    token,
    vote,
  },
});

export default function useStore(): RootStore {
  return baseUseStore(key);
}
