import { GetterTree } from 'vuex';
import { State } from '@/store/modules/auth';
import { RootState } from '@/store';
import { LoggedUser } from '@/interfaces/user';

export type Getters<S = State> = {
  currentUser(state: S): LoggedUser | undefined;
  isLogged(state: S): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  currentUser: (state) => {
    return state.currentUser;
  },
  isLogged: (state) => {
    return Boolean(state.token);
  },
};
