import { MutationTree } from 'vuex';
import { State } from '@/store/modules/auth';
import { LoggedUser } from '@/interfaces/user';

export enum MutationTypes {
  CURRENT_USER = 'SET_CURRENT_USER',
  TOKEN = 'SET_TOKEN',
}

export type Mutations<S = State> = {
  [MutationTypes.CURRENT_USER](state: S, payload?: LoggedUser): void;
  [MutationTypes.TOKEN](state: S, payload?: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.CURRENT_USER](state, payload) {
    state.currentUser = payload;
  },
  [MutationTypes.TOKEN](state, payload) {
    state.token = payload;
  },
};
