import axios from 'axios';
import { store } from '@/store';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import { ActionTypes as SettingsActionTypes } from '@/store/modules/settings/actions';

export const request = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use(
  async (config) => {
    const isLogged = store.getters.isLogged;
    if (isLogged) {
      config.headers.Authorization = `Bearer ${store.state.auth.token}`;
    }
    await store.dispatch(SettingsActionTypes.setIsLoading, true);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  async (response) => {
    await store.dispatch(SettingsActionTypes.setIsLoading, false);

    return response;
  },
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (
      (status === 400 || status === 401 || status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await store.dispatch(AuthActionTypes.logout);
    }

    return Promise.reject(error);
  }
);
