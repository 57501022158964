import { AxiosPromise } from 'axios';
import { ApiRoute } from '@/services/api/index';
import { request } from '@/utils/request';
import { Token, TokenList } from '@/interfaces/token';

export const getTokenById = (id: string): AxiosPromise<Token> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_BY_ID.replace('{id}', id),
  });
};

export const getTokenByLabel = (label: string): AxiosPromise<Token> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_BY_LABEL.replace('{label}', label),
  });
};

export const getTokenList = (): AxiosPromise<TokenList> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_LIST,
  });
};

export const getPromotedTokenList = (): AxiosPromise<Token[]> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_LIST_PROMOTED,
  });
};

export const getAllTimePopularTokenList = (): AxiosPromise<Token[]> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_LIST_ALL_TIME_POPULAR,
  });
};

export const getNewestTokenList = (): AxiosPromise<Token[]> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_LIST_NEWEST,
  });
};

export const getTodayPopularTokenList = (): AxiosPromise<Token[]> => {
  return request({
    method: 'get',
    url: ApiRoute.TOKEN_LIST_TODAY_POPULAR,
  });
};

export const createNewToken = (data: Token): AxiosPromise<Token> => {
  return request({
    method: 'post',
    url: ApiRoute.TOKEN_CREATE,
    data,
  });
};

export const updateToken = (data: Token): AxiosPromise<Token> => {
  return request({
    method: 'put',
    url: `${ApiRoute.TOKEN_UPDATE}?tokenID=${data._id}`,
    data,
  });
};

export const deleteToken = (data: Token): AxiosPromise => {
  return request({
    method: 'delete',
    url: `${ApiRoute.TOKEN_DELETE}?tokenID=${data._id}`,
  });
};
