import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { store, key } from '@/store';
import { i18n } from '@/plugins/i18n';

import { IonicVue } from '@ionic/vue';

/* Theme & theme variables */
import '@/theme/core.scss';
import '@/theme/variables.scss';

const app = createApp(App).use(IonicVue).use(router).use(store, key).use(i18n);

router.isReady().then(() => {
  app.mount('#app');
});
