import { MutationTree } from 'vuex';
import { State } from '@/store/modules/token';
import { Token, TokenList } from '@/interfaces/token';
import { Vote } from '@/interfaces/vote';

export enum MutationTypes {
  TOKEN_LIST = 'SET_TOKEN_LIST',
  VOTE_ADDED = 'SET_VOTE_ADDED',
  CURRENT_TOKEN = 'SET_CURRENT_TOKEN',
}

export type Mutations<S = State> = {
  [MutationTypes.TOKEN_LIST](state: S, payload?: TokenList): void;
  [MutationTypes.VOTE_ADDED](state: S, payload: Vote): void;
  [MutationTypes.CURRENT_TOKEN](state: S, payload?: Token): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.TOKEN_LIST](state, payload) {
    state.tokenList = payload;
  },
  [MutationTypes.VOTE_ADDED](state, payload) {
    if (!state.tokenList) return;

    if (state.tokenList.allToken) {
      const foundIndex = state.tokenList.allToken.findIndex(
        (token: Token) => token._id === payload.tokenId
      );

      if (foundIndex > -1) {
        state.tokenList.allToken[foundIndex].voteCount =
          state.tokenList.allToken[foundIndex].voteCount + 1;
      }
    }
    if (state.tokenList.unpublishedToken) {
      const foundIndex = state.tokenList.unpublishedToken.findIndex(
        (token: Token) => token._id === payload.tokenId
      );

      if (foundIndex > -1) {
        state.tokenList.unpublishedToken[foundIndex].voteCount =
          state.tokenList.unpublishedToken[foundIndex].voteCount + 1;
      }
    }
    if (state.tokenList.promotedToken) {
      const foundIndex = state.tokenList.promotedToken.findIndex(
        (token: Token) => token._id === payload.tokenId
      );

      if (foundIndex > -1) {
        state.tokenList.promotedToken[foundIndex].voteCount =
          state.tokenList.promotedToken[foundIndex].voteCount + 1;
      }
    }
    if (state.tokenList.allTime) {
      const foundIndex = state.tokenList.allTime.findIndex(
        (token: Token) => token._id === payload.tokenId
      );

      if (foundIndex > -1) {
        state.tokenList.allTime[foundIndex].voteCount =
          state.tokenList.allTime[foundIndex].voteCount + 1;
      }
    }
    if (state.tokenList.new) {
      const foundIndex = state.tokenList.new.findIndex(
        (token: Token) => token._id === payload.tokenId
      );

      if (foundIndex > -1) {
        state.tokenList.new[foundIndex].voteCount =
          state.tokenList.new[foundIndex].voteCount + 1;
      }
    }
    if (state.tokenList.today) {
      const foundIndex = state.tokenList.today.findIndex(
        (token: Token) => token._id === payload.tokenId
      );
      if (foundIndex > -1) {
        if (state.tokenList.today[foundIndex].voteItemsCount) {
          state.tokenList.today[foundIndex].voteItemsCount =
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            state.tokenList.today[foundIndex].voteItemsCount + 1;
        } else {
          state.tokenList.today[foundIndex].voteCount =
            state.tokenList.today[foundIndex].voteCount + 1;
        }
      }
    }
  },
  [MutationTypes.CURRENT_TOKEN](state, payload) {
    state.currentToken = payload;
  },
};
