import { AxiosPromise } from 'axios';
import { ApiRoute } from '@/services/api/index';
import { request } from '@/utils/request';
import { LoggedUser, User } from '@/interfaces/user';

interface LoginResponse {
  token: string;
  user: LoggedUser;
}

export const login = (data: User): AxiosPromise<LoginResponse> => {
  return request({
    method: 'post',
    url: ApiRoute.AUTH_LOGIN,
    data,
  });
};

export const register = (data: User): AxiosPromise<LoginResponse> => {
  return request({
    method: 'post',
    url: ApiRoute.AUTH_REGISTER,
    data,
  });
};
